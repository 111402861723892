<template>
    <Dialog title="修改人" width="50%" :visible="dialogShow" class="f-cl-c" @closeDialog="closeDialog">
        <el-table ref="modifyPersonTable" :data="modifyPersonList" tooltip-effect="dark" style="width: 100%" class="table"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="全选">
                <template slot-scope="scope">
                    <div class="f-c item f1">
                        <el-image class="item-image" :src="scope.row.userAvatarUrl" fit="fill"></el-image>
                        <div class="f-cl baseInfo">
                            <span>{{ scope.row.ecardName }}</span>
                            <span>联系方式：{{ scope.row.ecardPhone }}</span>
                            <span>所属部门：{{ scope.row.ecardNote ? scope.row.ecardNote : '未设置' }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <Pagination :margin="'20px'" :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
            @pagination="fetchData" />
        <div class="f-c-a btns">
            <el-button @click="reset()">重置</el-button>
            <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
    </Dialog>
</template>

<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
export default {
    name: 'ModifyPersonal',
    props: ['dialogShow', 'isHandleReset'],
    mixins: [PaginationRetention],
    data() {
        return {
            modifyPersonList: [],//修改人列表
            mofigyPersonIdList: [],//修改人列表id
            searchUserIdList: [],//操作人id集合
        }
    },
    watch: {
        dialogShow(val) {
            if (val && this.isHandleReset) {
                this.reset()
            }
        }
    },
    methods: {
        getDate() {
            this.productStockSearchOperator(this.paging, this.pagingSize);
        },
        // 产品库存单据操作人搜索查询接口
        productStockSearchOperator(page, size = this.size) {
            let that = this;
            that.$http.productStockSearchOperator({
                changeUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                current: page,
                size,
                changeUsername: ''
            }).then(res => {
                that.modifyPersonList = res.data.records;
                that.total = res.data.total;
                that.currentPage = res.data.current;
            })
        },
        handleSelectionChange(val) {
            let searchUserIdList = [],
                userNameArr = []
            val.forEach((item) => {
                searchUserIdList.push(item.userId)
                userNameArr.push(item.ecardName)
            })
            this.searchUserIdList = searchUserIdList
            this.userNames = userNameArr.join(',')
        },
        // 关闭弹框
        closeDialog() {
            this.$emit('closeDialog', false)
            this.$removeStorage('fetchDataPage')
            this.$removeStorage('fetchDataSize')
        },
        // 重置
        reset() {
            this.$refs.modifyPersonTable.clearSelection();
        },
        // 选中修改人
        handleSubmit() {
            let data = { searchUserIdList: this.searchUserIdList, userNames: this.userNames }
            this.$emit('getUserIdList', data)
            this.closeDialog()
        }
    },
}

</script>
<style lang='less' scoped>
.table {
    max-height: 460px;
    overflow-y: auto;

    .item {
        padding: 5px;

        .item-image {
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }

        .baseInfo {
            margin-left: 30px;

            span {
                font-size: 14px;
            }

            span:first-child {
                font-size: 20px;
            }

            span:nth-child(2) {
                margin: 5px 0;
            }
        }
    }

}

/deep/ .el-table::before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
}

.btns {
    margin-top: 20px;

    .el-button {
        width: 30%;
    }
}
</style>
